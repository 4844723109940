
import Vue, { PropType } from 'vue'
import { ManualCommunication } from '@/types/GCB2'
import {
    TASKLIST_ACTION_TYPES,
    TASKLIST_AVAILABLE_ACTIONS,
    TASKLIST_COMMUNICATIONS_TYPES,
    TASKLIST_STATUSES,
} from '../../../../vars/GCB2'

import taskListActions from '@/components/chisai/GCB2/taskList/actions'
import * as t from '@/store/chisai/GCB2/actionTypes'
import ModaDialog from '../taskList/ModaDialog.vue'
import { Project } from '../../../../types/main'
import SendMessageDialog from '../taskList/SendMessageDialog.vue'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import useSendMessageDisabled from '../hooks/useSendMessageDisabled'
import { getActivation } from '@root/src/api/chisai/GCB2'
import { useModalStore } from '@root/src/store/stores/modal'
import config from '@root/config'

export default Vue.extend({
    components: { ModaDialog, SendMessageDialog },
    props: { manualCommunication: { type: Object as PropType<ManualCommunication> } },
    data: () => ({
        TASKLIST_ACTION_TYPES,
        TASKLIST_STATUSES,
        isPostponeDialog: false,
        isModaDialog: false,
        activeItem: null as null | any,
        changeCommentMode: null as null | 'ONLY_COMMENT' | 'DONT_REMIND',
        isChangeCommentDialog: false,
        isSendMessageDialog: false,
        sendMessageErrors: null as any,
        actions: []
    }),

    computed: {
        ...mapStores(useGcb2Store),
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        waSettingsLink(): string {
            let link = ''
            const conns = this.project.connectors

            let waBasisConn = null
            for (let conn of conns) {
                if (conn.connectorType == 'whatsappBasis') waBasisConn = conn
            }
            let waOldConn = null
            for (let conn of conns) {
                if (conn.connectorType == 'whatsapp') waOldConn = conn
            }

            if (waBasisConn) {
                link = `/project/${this.project.id}/connectorSettings/${waBasisConn.connectorId}`
            } else if (waOldConn) {
                link = `/project/${this.project.id}/connectorSettings/${waOldConn.connectorId}`
            } else {
                link = `/project/${this.project.id}/addConnectorStage1`
            }
            return link
        },
        showActionModalOnContact(): boolean {
            const gcb2Connector = this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.GCB2
            )
            return Boolean(gcb2Connector!.showActionModalOnContact)
        },
        userId(): string {
            return this.$store.getters.myUser.sub
        },
        loadingActionsCommunicationIds(): string[] {
            return this.$store.state.GCB2.taskList.loadingActionsCommunicationIds
        },
        callButtonDisabled(): boolean {
            return this.$store.state.GCB2.taskList.disabledCallCommunicationIds.includes(
                this.manualCommunication.communicationId
            )
        },
        buttonsDisabled(): boolean {
            if (this.manualCommunication && !status) {
                return this.loadingActionsCommunicationIds.includes(this.manualCommunication.communicationId)
            } else {
                return false
            }
        },
        waBtnDisabled(): boolean {
            const connectors = this.project.connectors
            const oldBot = connectors.find(connectors => connectors.connectorType === 'whatsapp')
            const newBot = connectors.find(connectors => connectors.connectorType === 'whatsappBasis')
            if (oldBot && oldBot?.alive) return false
            if (newBot && newBot?.alive) return false
            return true
        },
        sendMessageError(): string | undefined {
            if (this.sendMessageErrors) {
                return this.sendMessageErrors.findError(this.manualCommunication)
            }
        },
        modalStore(): any {
            return useModalStore()
        },
    },

    methods: {
        action() {
            this.manualCommunication.status = 'Нужно связаться'
            this.$emit('refresh')
        },
        isActionAvailable: taskListActions.isActionAvailable,
        openModaDialog(item: any) {
            this.activeItem = item
            this.isModaDialog = true
        },
        openActionModal(item: ManualCommunication, actionType: string) {
            this.gcb2Store.openActionModal({
                actionType,
                communicationId: item.communicationId,
                opened: true,
            })
        },
        openChangeCommentDialog(item: any) {
            this.activeItem = item
            this.changeCommentMode = 'ONLY_COMMENT'
            this.isChangeCommentDialog = true
        },
        openDontRemindDialog(item: any) {
            this.activeItem = item
            this.changeCommentMode = 'DONT_REMIND'
            this.isChangeCommentDialog = true
        },
        async dontContactAction(item: any) {
            await taskListActions.dontContact(this, item)
        },
        async remindAction(item: any) {
            await taskListActions.remind(this, item)
        },
        async makeCall(item: any) {
            await taskListActions.makeCall({ vueInstanse: this, item, project: this.project })
        },
        async contact(item: any) {
            if (this.showActionModalOnContact) {
                await this.openActionModal(item, TASKLIST_ACTION_TYPES.CONTACT)
            } else {
                await taskListActions.contact({
                    vueInstanse: this,
                    item,
                    commType: TASKLIST_COMMUNICATIONS_TYPES.NO,
                })
            }
        },
        openSendMessageDialog(item: any) {
            this.activeItem = item
            this.isSendMessageDialog = true
        },
        async checkOldBot(item: any) {
            let preferedBotInActivation = ''
            const oldBotInConnectors = this.project.connectors.find(
                connector => connector.connectorType === 'whatsapp'
            )
            const { data, error } = await getActivation({
                projectId: this.project.id,
                id: item.activationId,
            })
            if (!error) {
                if (data.extra?.preferedBot) {
                    preferedBotInActivation = data.extra?.preferedBot.split('_')[1]
                }
            }
            if (preferedBotInActivation === 'whatsapp' && !oldBotInConnectors?.alive) {
                this.modalStore.open({
                    text: `Отсутствует авторизация, отправка сообщений невозможна.`,
                    link: [
                        `${config.frontendEndpoint}/project/${this.project.id}/connectorSettings/${oldBotInConnectors.connectorId}`,
                    ],
                    linkText: ['Перейти в настройки бота'],
                })
            } else {
                this.openSendMessageDialog(item)
            }
        },
    },
    async created() {},
    mounted() {
        let { findError } = useSendMessageDisabled()
        this.sendMessageErrors = { findError }
        const a = TASKLIST_AVAILABLE_ACTIONS[this.manualCommunication.status]
        this.actions = a;
    },
    beforeUpdate(): void {
        const a = TASKLIST_AVAILABLE_ACTIONS[this.manualCommunication.status]
        console.log(a)
        this.actions = a;
    }
})
